import * as React from "react"
import {Link} from "gatsby"
import * as styles from "./postList.module.css"

const PostList = ({posts}) => (
    <ol className={styles.list}>
        {posts.map(post => {
            return <li key={post.postId}
                       className={styles.item}>
                <Link to={`/${post.category.slug}/${post.postId}`} itemProp="url">
                    <article className={styles.article}>
                        <img className={styles.eyeCatch}
                             src={`${post.eyecatch.url}?auto=format&auto=compress&fm=webp&max-w=384&fit=fill`}
                             alt={post.title}
                        />
                        <header>
                            <span className={styles.category}>{post.category.name}</span>
                            <h2 className={styles.title}>
                                <span itemProp="headline">{post.title}</span>
                            </h2>
                            <span className={styles.publishedAt}>{post.publishedAt}</span>
                        </header>
                    </article>
                </Link>
            </li>
        })}
    </ol>
)

export default PostList
