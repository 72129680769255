import React, {useEffect} from "react"
import {Link, graphql} from "gatsby"
import Helmet from "react-helmet";

import Layout from "../components/layout"
import Seo from "../components/seo"
import PostDetail from "../components/postDetail";
import * as styles from "./post.module.css"
import SectionLabel from "../components/sectionLabel";
import PostList from "../components/postList";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faHome, faBookmark} from "@fortawesome/free-solid-svg-icons"

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                siteUrl
            }
        }
    }
`

const PostPage = ({data, pageContext}) => {
    const {category, post, previousPost, nextPost, relatedPosts} = pageContext

    // 描画後にInstagramのスクリプトを呼び出す
    useEffect(() => {
        if (window.instgrm) {
            window.instgrm.Embeds.process()
        }
    }, [post]);

    const imageUrl = `${post.eyecatch.url}?auto=compress,format&fm=webp&w=1200&h=630&fit=crop&blend64=${post.titleBlend64}&blend-mode=normal&blend-align=middle,center`

    return (
        <Layout>
            <Seo title={post.title}
                 description={post.description}
                 siteUrl={`${data.site.siteMetadata.siteUrl}/${category.slug}/${post.postId}`}
                 siteType='article'
                 meta={[
                     {
                         property: `og:image`,
                         content: imageUrl,
                     },
                     {
                         property: `og:image:secure_url`,
                         content: imageUrl,
                     },
                     {
                         property: `og:image:width`,
                         content: 1200,
                     },
                     {
                         property: `og:image:height`,
                         content: 630,
                     },
                     {
                         property: `thumbnail`,
                         content: imageUrl,
                     },
                     {
                         property: `twitter:image`,
                         content: imageUrl,
                     },
                 ]}/>

            <Helmet>
                <script async src="https://www.instagram.com/embed.js"/>
            </Helmet>

            <nav className="breadcrumb">
                <ol>
                    <li>
                        <Link to="/"><FontAwesomeIcon icon={faHome}/></Link>
                    </li>
                    <li>
                        <Link to={`/${category.slug}`}>{category.name}</Link>
                    </li>
                </ol>
            </nav>

            <PostDetail post={post}/>

            <div className={styles.pager}>
                {
                    nextPost ? (
                        <div className={styles.block}>
                            <Link to={`/${nextPost.category.slug}/${nextPost.postId}`}>
                                <span className={`${styles.pagerLabel} ${styles.previous}`}>&lt; 前の記事</span>
                                <div className={styles.background}
                                     style={{backgroundImage: `url(${nextPost.eyecatch.url}?auto=format&auto=compress&fm=webp&blur=60)`}}>
                                    <div className={styles.shade}>
                                        <span>{nextPost.title}</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ) : <div className={styles.block}/>
                }
                {
                    previousPost ? (
                        <div className={styles.block}>
                            <Link to={`/${previousPost.category.slug}/${previousPost.postId}`}>
                                <span className={`${styles.pagerLabel} ${styles.next}`}>次の記事 &gt;</span>
                                <div className={styles.background}
                                     style={{backgroundImage: `url(${previousPost.eyecatch.url}?auto=format&auto=compress&fm=webp&blur=60)`}}>
                                    <div className={styles.shade}>
                                        <span>{previousPost.title}</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ) : <div className={styles.block}/>
                }
            </div>

            {relatedPosts.length > 0 ?
                <section className={styles.relatedPosts}>
                    <SectionLabel><span className={styles.labelIcon}><FontAwesomeIcon
                        icon={faBookmark}/></span>関連記事</SectionLabel>
                    <PostList posts={relatedPosts}/>
                </section> : null}
        </Layout>
    )
}

export default PostPage